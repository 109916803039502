<template>
  <div class="grid">
    <div class="col-1"></div>
    <div class="col-10">
      <div
        class="p-grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
      >
        <div class="flex justify-content-between align-items-center mb-1">
          <h2 class="font-normal text-black-alpha-90 my-3">
            Visits and Responses By Date
          </h2>
          <div></div>
        </div>
        <Chart
          type="line"
          :data="chartData"
          responsive="false"
          width="300"
          height="100"
        />
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-2"></div>
    <div class="col-8">
      <div class="mt-5">
        <DataTable
          :value="tableData"
          responsiveLayout="scroll"
          showGridlines
          stripedRows
        >
          <Column field="date" header="Date"></Column>
          <Column field="day_of_week" header="Day of Week"></Column>
          <Column
            field="total"
            header="Visits"
            bodyStyle="text-align:right"
          ></Column>
          <Column
            field="leads"
            header="Leads"
            bodyStyle="text-align:right"
          ></Column>
          <Column
            field="conversion_percentage"
            header="Conversion"
            bodyStyle="text-align:right"
          ></Column>
          <Column
            field="visits_total"
            header="Total Visits"
            bodyStyle="text-align:right"
          ></Column>
          <Column
            field="leads_total"
            header="Total Leads"
            bodyStyle="text-align:right"
          ></Column>
          <Column
            field="total_conversion_percentage"
            header="Total Conversion"
            bodyStyle="text-align:right"
          ></Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "primevue/chart";
export default {
  components: {
    Chart,
  },
  props: {
    performanceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: null,
      tableData: null,
    };
  },
  mounted() {
    console.log(Object.keys(this.performanceData));
    this.chartData = {
      labels: Object.keys(this.performanceData),
      datasets: [
        {
          label: "Visits",
          data: Object.keys(this.performanceData).map(
            (key) => this.performanceData[key].total
          ),
          fill: false,
          borderColor: "#42A5F5",
          tension: 0.4,
          legend: false,
        },
        {
          label: "Leads",
          data: Object.keys(this.performanceData).map(
            (key) => this.performanceData[key].leads
          ),
          fill: true,
          borderColor: "red",
          tension: 0.4,
          legend: false,
        },
      ],
    };
    this.tableData = this.performanceData;
  },
};
</script>
